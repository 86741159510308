export enum UserRoles {
  superAdmin = 'superAdmin',
  admin = 'admin',
  tenant = 'tenant',
  vigilant = 'vigilant',
  proprietor = 'proprietor',
  committee = 'committee',
  collaborator = 'collaborator',
  external = 'external',
  customized = 'customized',
}

export enum ColumnToExcelDB {
  unit_id = 'Unidad',
  user_name = 'Nombre Completo',
  is_tenant = '¿Es residente?',
  is_proprietor = '¿Es propietario?',
  is_committe = '¿Es miembro de la junta?',
  user_email = 'Correo electrónico',
  phone_code = 'Código de país',
  phone_detail = 'Número de teléfono'
}
export interface PhoneDetail {
  countryCode: string;
  dialCode: string;
  e164Number: string;
  nationalNumber: string;
}

export interface User {
  id: string;
  uid?: string;

  email: string;
  name: string;
  lastName: string;
  phone: string;
  phoneDetail: PhoneDetail;
  codeWhatsapp: string;
  dni: string;
  photoUrl: string;
  superUser: boolean;
  config: any | string;

  termsConditions: {
    eventAt: Date;
    version: string;
    platform: string;
    history?: { version: string; eventAt: Date; platform: string }[];
  };

  i18n: string;

  lastConnection?: Date;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface UserInfoTab {
  id: string,
  name: string,
  email: string,
  dni: string,
  phoneDetail: any,
  unit: {
    unitId: string,
    unitNumber: string
  },
  roles: string[],
  userBuilding: string,
  status: string,
  inCommittee: boolean
}

interface Paginated {
  totalDocs: number,
  limit: number,
  page: number,
  totalPages: number,
  nextPage: number,
  prevPage: number,
  pagingCounter: number,
  hasPrevPage: boolean,
  hasNextPage: boolean,
  docs: UserInfoTab[]
}



export interface ResponsePaginatedUsers {
  data: Paginated
}

export interface PayloadUpdateUser {
  dni: string;
  name: string;
  roles: UserRoles[];
  phone?: string;
  email?: string;
  unit?: string;
  building?: string;
  phoneDetail?: {
    countryCode: string;
    dialCode: string;
    e164Number: string;
    nationalNumber: string;
  };
}
export interface ExcelInfoToUpload {
  originalData: OriginalDataFromUsersXLSX[];
}

export type OriginalDataFromUsersXLSX = {
  [key in ColumnToExcelDB]?: string | null;
}

export interface PayloadUpdateTmpUser {
  name: string;
  roles: UserRoles[];
  email: string;
  unit: string;
}

export enum PlatformDevice {
  ios = 'ios',
  android = 'android',
  webpage = 'webpage'
}

export interface DevicePermissions {
  notification: boolean;
  contact: boolean;
  multimedia: boolean;
  microphone: boolean;
  recordVideos: boolean;
}

export interface DeviceInformation {
  permissions: DevicePermissions;
  platform: PlatformDevice;
  osVersion: string;
  appVersion: string;
  updatedAt: string;
  id: string;
}

export enum TmpUserStatus {
  accepted = 'accepted',
  created = 'created',
  deleted = 'deleted',
}