import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InformativeModalData } from 'src/app/interface/data.interface';

@Component({
  selector: 'app-informative-modal',
  templateUrl: './modal-informative.html',
  styleUrls: ['./modal-informative.scss']
})
export class InformativeModalComponent implements OnInit {
  data!: InformativeModalData;

  constructor(@Inject(MAT_DIALOG_DATA) private payload: InformativeModalData) {
    this.data = this.payload
  }

  ngOnInit(): void {

  }

}
