<div class="body-card grey-rounded-scroll" *ngIf="accessEntries">
  <div class="invitation-record" [ngClass]="door.checked && door.status === 'completed' ? 'completed' : door.status === 'time-exceeded' ? 'time-exceeded' : ''" *ngFor="let door of accessEntries; let i = index">
    <div class="last-marking">
      <p class="door-name">{{ door?.doorName }}</p>
      <p class="marking" *ngIf="door.status !== 'time-exceeded'">
        {{ 'frequentAccess.securityCenter.marking' | translate }}: {{ door?.date ? (door.date | date:'dd MMM. YYYY - HH:mm a') : '--' }}
      </p>
      <div class="marking time-exceeded" *ngIf="door.status == 'time-exceeded'">
        {{ 'frequentAccess.securityCenter.exceeded.at' | translate }} : {{ door?.date ? (door.date | date:'HH:mm') : '--:--' }} | 
        {{ 'frequentAccess.securityCenter.exceeded.by' | translate }}:
        <p class="hours">
          <span>{{door.timeEx.hours}} : {{door.timeEx.minutes}}</span>
          <span>HRS : MIN</span>
        </p>
      </div>
    </div>
    <div *ngIf="door?.checked || door?.status == 'time-exceeded'">
      <div *ngIf="door?.status != 'time-exceeded'" class="custom__status scanned">{{ ('status.invitations.scanned') | translate | uppercase }}</div>
      <div *ngIf="door?.status == 'time-exceeded'" class="custom__status time-exceeded">{{ ('status.invitations.time-exceeded') | translate | uppercase }}</div>
    </div>
  </div>
</div>