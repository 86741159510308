<div class="d-flex align-items-center main">
  <label class="photo col-6" for="photo" (click)="openCamera()">
    {{ 'frequentAccess.buttons.useCamera' | translate }}
    <img src="../../../assets/images/icons/photo_camera.svg" class="camera" alt="camera" height="18">
  </label>
  <img *ngIf="previewImage" class="check" src="https://munily-public-cdn.s3.amazonaws.com/general/checkGreenCircle2D.png" alt="check">
</div>

<ng-template #WebcamFeature>
  <div class="container">
    <div class="camView col-12">
      <webcam
        *ngIf="takingPhoto"
        class="webcam"
        [trigger]="$trigger"
        [captureImageData]="true"
        [mirrorImage]="'never'" 
        [allowCameraSwitch]="allowCameraSwitch"
        [imageType]="'image/jpeg'"
        [imageQuality]="1"
        [switchCamera]="$switchCameratrigger"
        (initError)="handleInitError($event)" 
        (imageCapture)="snapShot($event)"
        (cameraSwitched)="cameraSwitched($event)" 
      >
      </webcam>
      <div class="preview" *ngIf="!takingPhoto">
        <img [src]="previewImage.imageAsDataUrl" alt="preview">
      </div>
      <button *ngIf="allowCameraSwitch" class="btn btn-change-camera" (click)="switchCam()"><img src="../../../assets/images/camera/repeat-solid.svg" alt="change"></button>
    </div>
    <div class="controls col-12">
      <button class="btn btn-retry" (click)="takingPhoto = true"><img src="../../../assets/images/camera/camera-rotate-solid.svg" alt="retry"></button>
      <button class="btn btn-take-photo" (click)="takePhoto()"></button>
      <button class="btn btn-send" (click)="sendImageAsBase64()"><img src="../../../assets/images/camera/share-solid.svg" alt="retry"></button>
    </div>
  </div>
</ng-template>
