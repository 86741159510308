<mat-dialog-content class="custom__class-dialog" [ngClass]="data.type" *ngIf="data">
  <img *ngIf="data.icon" [src]="data.icon" alt="icon modal" width="48px" height="48px">
  <h1>{{ data.title }}</h1>
  <p *ngIf="data.message">{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions class="custom__class-dialog-buttons" [ngClass]="data.type">
  <ng-container *ngFor="let button of data.buttons">
    <button class="btn" [ngClass]="button?.css" [mat-dialog-close]="button.closeDialog">
      {{ button.text }}
    </button>
  </ng-container>
</mat-dialog-actions>