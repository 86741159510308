import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FEATURES_EXCLUDE_SIDEBAR } from 'src/app/interface/features.interface';
import { DataService } from '../../services/data.service';
import { GeneralConfigService } from '../../services/general-config.service';
import { ROUTES, SUB_MENU_ITEMS, mainFeatures } from './menu-items';
import { CallProviders } from 'src/app/interface/calls.interface';


declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  currentLanguage: string = 'es';
  public sidebarnavItems = [];
  contability: boolean = false;

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(public dataService: DataService, private translateService: TranslateService, public generalConfigService: GeneralConfigService) { }

  // End open close
  async ngOnInit() {
    this.currentLanguage = this.translateService.store.currentLang;
    setTimeout(() => {
      const user = this.dataService.getUser();
      const plan = this.dataService.getPlan();
      const buildingCofig = this.dataService.getBuilding().config;
      const role = user.roles ? user.roles[0] : 'superAdmin';
      const roles = user.roles ? user.roles : 'superAdmin';


      user.superUser ? this.sidebarnavItems.push(ROUTES[mainFeatures.dashboardSuperAdmin]) : this.sidebarnavItems.push(ROUTES[mainFeatures.dashboard])

      if (user.superUser) {
        this.sidebarnavItems.push(ROUTES[mainFeatures.munilyPay]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.buildings]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.communities]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.usersSuperAdmin])
        this.sidebarnavItems.push(ROUTES[mainFeatures.services]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.releases]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.knowledgeCenter]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.door]);;

      } else if (roles.indexOf('customized') > -1) {
        this.sidebarnavItems.length = 0;
        this.builtCustomizedMenu(user.features);
      } else {
        /* TEMPORAL */
        if (roles.indexOf('admin') > -1) {
          // Optional items, which depends on the general configuration conditions
          this.generalConfigService.getGeneralConfigFeatures().subscribe((config: any) => {
            if (buildingCofig.chatV2 && buildingCofig.chatV2.enable) {
              this.sidebarnavItems.splice(1, 0, ROUTES[mainFeatures.chats]);
            }
            if (config.data.feature && config.data.feature.contability) this.contability = true;
            if (this.contability) {
              this.sidebarnavItems.splice(1, 0, ROUTES[mainFeatures.accountStatements]);
            }

            if (buildingCofig.pqrs && buildingCofig.pqrs.enable) {
              this.sidebarnavItems.splice(1, 0, ROUTES[mainFeatures.pqrs]);
            }
          });

          if (buildingCofig.fintech && buildingCofig.fintech.enable) this.sidebarnavItems.push(ROUTES[mainFeatures.munilyPayTransactions]);

          const accessControl = buildingCofig.accessControl;
          if (accessControl && accessControl.preRegisterVisit && (accessControl.preRegisterVisit.enable || accessControl.vigilantVisit.enable)) {
            this.sidebarnavItems.push(ROUTES[mainFeatures.controlAccess]);
          }

          if (buildingCofig.directory?.enable || buildingCofig.invitation?.directCallVigilant) {
            this.sidebarnavItems.push(ROUTES[mainFeatures.vigilantCalls]);
          }

          //General Items
          this.sidebarnavItems.push(
            ROUTES[mainFeatures.notices],
            ROUTES[mainFeatures.reservations],
            ROUTES[mainFeatures.visits],
            ROUTES[mainFeatures.frequentAccess],
            ROUTES[mainFeatures.administrativeDropdown],
            ROUTES[mainFeatures.knowledgeCenter]
          );

          //Munily Providers
          if (buildingCofig.munilyPh && buildingCofig.munilyPh.enable) {
            ROUTES[mainFeatures.munilyProviders].path = buildingCofig.munilyPh.link;
            this.sidebarnavItems.push(ROUTES[mainFeatures.munilyProviders]);
          }
        }
      }
    }, 300);
  }

  builtCustomizedMenu(features) {
    features.forEach((feature) => {
      if (feature.enable) {
        feature.feature.forEach((userFeature) => {
          if (FEATURES_EXCLUDE_SIDEBAR.indexOf(userFeature) < 0) {
            // Feature is a subMenu item
            if (this.checkIsSubmenuItem(userFeature)) {
              const mainMenu = this.getMenuItem(userFeature);
              if (!ROUTES[mainMenu].submenu.includes(ROUTES[userFeature])) ROUTES[mainMenu].submenu.push(ROUTES[userFeature]);
              //Validate if parent Menu Item is in the sideBarNav list, if not added
              if (this.sidebarnavItems.indexOf(ROUTES[mainMenu]) < 0) this.sidebarnavItems.push(ROUTES[mainMenu])
            } else { //Feature is a main menu item
              if (this.sidebarnavItems.indexOf(ROUTES[userFeature]) < 0) this.sidebarnavItems.push(ROUTES[userFeature])
            }
          }
        })
      }
    })
  }

  checkIsSubmenuItem(userFeature) {
    const subItem = SUB_MENU_ITEMS.find(element => element.submenu === userFeature);
    if (subItem) return true
    return false;
  }

  getMenuItem(userFeature) {
    const subItem = SUB_MENU_ITEMS.find(element => element.submenu === userFeature);
    if (subItem) return subItem.main
  }
}
