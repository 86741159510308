import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { AccessEntryStatus, AccessTypeMacro } from 'src/app/interface/frequentAccess.inteface';
import { BuildingAdminMacro } from 'src/app/macro-community/interfaces/building.interface';
import { BuildingMacroCommunityService } from 'src/app/macro-community/services/building.service';
import { BuildingService } from 'src/app/services/building.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';

export enum TypeFilters {
  search = 'search',
  company = 'company',
  locals = 'locals',
  type = 'type',
  state = 'state',
  door = 'door',
  dateRange = 'dateRange'
}

@Component({
  selector: 'app-filters-common',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  floatLabel: FloatLabelType = 'never';
  formFilters: FormGroup;

  searchLengthCompany: number = 0;
  isOpenPanelCompany: boolean = true;
  isOpenPanelUnit: boolean = true;
  isOpenPanelTypeVisit: boolean = true;
  isOpenPanelState: boolean = true;
  isOpenPanelDoor: boolean = true;

  IShowFilters = TypeFilters;

  isClearFilters: boolean = false;

  isPickerOpen = false;

  @Input() showfilters: TypeFilters[] = [];
  @Input() accesType: string;
  @Input() state: string;
  @Input() isMacro: boolean = false;
  @Input() buildingId: string;
  @Output() outputFilterParams: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();

  communities: BuildingAdminMacro[];
  units: { id: string; number: string }[];
  doorList: { id: string, name: string }[];

  accesTypeList = [
    { value: AccessTypeMacro.COLLABORATOR, display: 'frequentAccess.accessTypes.collaborator' },
    { value: AccessTypeMacro.VISIT, display: 'frequentAccess.accessTypes.visit' },
    { value: AccessTypeMacro.PROVIDER, display: 'frequentAccess.accessTypes.provider' }
  ];

  accesStateList = [
    { value: AccessEntryStatus.active, display: 'frequentAccess.securityCenter.statusEntry.entry' },
    { value: AccessEntryStatus.displacement, display: 'frequentAccess.securityCenter.statusEntry.displacement' },
    { value: AccessEntryStatus.timeExceeded, display: 'frequentAccess.securityCenter.statusEntry.timeExceeded' },
    { value: AccessEntryStatus.completed, display: 'status.invitations.left' },
    { value: AccessEntryStatus.completedBySystem, display: 'frequentAccess.securityCenter.statusEntry.noLeave' }
  ];

  params: HttpParams = new HttpParams()

  constructor(private formBuilder: FormBuilder,
    private buildingMacroCommunityService: BuildingMacroCommunityService,
    private buildingService: BuildingService,
    private translate: TranslateService) {

    this.loadFilters();
  }

  ngOnInit(): void {
    this.showfilters.forEach(filter => {
      if (filter === this.IShowFilters.search) this.observerChangeSearchForm();
      if (filter === this.IShowFilters.company) this.getReadyCommunityInput(this.buildingId);
      if (filter === this.IShowFilters.locals) this.getReadyUnitInput(this.buildingId);
      if (filter === this.IShowFilters.door) this.getReadyDoorInput(this.buildingId);
    })
  }

  loadFilters(filters?: any) {
    this.formFilters = this.formBuilder.group({
      search: [''],
      company: [''],
      unit: [''],
      type: [''],
      state: [''],
      door: [''],
      startDate: [''],
      endDate: ['']
    })
  }

  buttonCrearSearch() {
    this.formFilters.get('search').reset();
  }

  isOpen() {
    this.isOpenPanelCompany = !this.isOpenPanelCompany;
  }

  // Load company
  getReadyCommunityInput(buildingId: string) {
    this.buildingMacroCommunityService
      .getCommunitiesBuilding({ all: 'true', buildingId })
      .subscribe((res) => {
        this.communities = res.docs;
      });
  }

  // Load locals 
  getReadyUnitInput(buildingId: string) {
    this.buildingService.getUnitsByBuilding(buildingId).subscribe(
      (res: any) => (this.units = res.data.map((e) => ({ id: e.unitId, number: e.unitNumber }))),
      (err) => console.error(err),
    );
  }

  // Load Doors
  getReadyDoorInput(buildingId: string) {
    this.buildingService.getDoorsBuilding(buildingId).subscribe({
      next: (value: { data: { id: string, name: string }[] }) => {
        this.doorList = value.data;
      },
      error: (err) => {
        console.error('err', err);
      },
    }
    );
  }

  observerChangeSearchForm() {
    this.formFilters.get('search').valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (!value) {
          this.isClearFilters = false;
          this.params = this.params.delete('search');
          return;
        }

        if (this.params.has('search')) this.params.delete('search', value);
        this.params = this.params.set('search', value);
        this.outputFilterParams.emit(this.params);
        this.isClearFilters = true;
      });
  }

  selectCompany(company: any) {
    this.params = this.params.set('buildingId', company.buildingId)

    this.formFilters.controls['unit'].reset();
    this.formFilters.get('company')?.setValue(this.translate.instant(company.buildingName));

    this.getReadyUnitInput(company.buildingId);

    this.outputFilterParams.emit(this.params);
    this.isClearFilters = true;
  }

  selectUnit(unit: any) {
    this.formFilters.get('unit')?.setValue(unit.number);
    this.params = this.params.set('unit', unit.id)
    this.outputFilterParams.emit(this.params);
    this.isClearFilters = true;
  }

  selectTypeVisit(type: any) {
    this.formFilters.get('type')?.setValue(this.translate.instant(type.display));

    this.params = this.params.set('type', type.value)
    this.outputFilterParams.emit(this.params);
    this.isClearFilters = true;
  }

  selectState(state: any[]) {
    if (this.params.has('status')) this.params = this.params.delete('status');

    if (state.length === 0) {
      this.formFilters.controls['state'].reset();
      this.outputFilterParams.emit(this.params);
      this.isClearFilters = false;
      return;
    }

    state.forEach(c => this.params = this.params.append('status', c.value));

    this.outputFilterParams.emit(this.params);
    this.isClearFilters = true;
  }

  selectDoor(door: any) {
    this.params = this.params.set('doorId', door.id)
    this.formFilters.get('door')?.setValue(door.name);

    this.outputFilterParams.emit(this.params);
    this.isClearFilters = true;
  }

  selectDateRange() {
    const startDate = this.formFilters.get('startDate')?.value;
    const endDate = this.formFilters.get('endDate')?.value;

    if (!startDate || !endDate) return;

    if (this.params.has('startDate')) this.params = this.params.delete('startDate');
    if (this.params.has('endDate')) this.params = this.params.delete('endDate');

    this.params = this.params.set('startDate', moment(startDate).startOf('day').format('YYYY-MM-DD'));
    this.params = this.params.set('endDate', moment(endDate).startOf('day').format('YYYY-MM-DD'));
    this.outputFilterParams.emit(this.params);
    this.isClearFilters = true;
    this.isPickerOpen = true;
  }

  resetFilters() {
    this.formFilters.reset();

    this.params = new HttpParams();

    this.isClearFilters = false;
    this.outputFilterParams.emit(this.params);
  }

  btnClearControl(control: string, params: string) {
    if (!this.formFilters.get(control)) return;

    this.formFilters.get(control).reset();

    if (!this.params.has(params)) return;

    this.params = this.params.delete(params);
    this.outputFilterParams.emit(this.params);

    if (this.params.keys.length === 0) this.isClearFilters = false;
  }

  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }
}
