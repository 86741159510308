import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { ExcelInfoToUpload, PayloadUpdateTmpUser, PayloadUpdateUser, PhoneDetail } from '../interface/users.interface';
import { DataService } from './data.service';

export enum UserStatus {
  active = 'active',
  inactive = 'inactive',
}
@Injectable()
export class UserService extends DataService {
  headers: HttpHeaders;
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).token : null,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).fbToken : null,
    });
  }
  page = 1;
  rowsPerPage = 10;

  setHeaders() {
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).token : null,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).fbToken : null,
    });
  }

  //Core Nuevo (Backend-Services)

  addSortToQuery(query: string, sort: { field: string; order: string }) {
    let newQuery = '';
    if (query.includes('sortField')) {
      newQuery = query.split('&sortField')[0];
    }
    query = newQuery + `&sortField=${sort.field}&sortOrder=${sort.order}`;
    return query;
  }

  getUsers(page: number, limit: number, query?:any) {
    let params = new HttpParams().set('page', page).set('rowsPerPage', limit);
    if (query.name) params = params.set('name', query.name);
    if (query.email) params = params.set('email', query.email);
    if (query.unitId) params = params.set('unitId', query.unitId);
    if (query.roles) params = params.set('roles', query.roles);
    if (query.sortField) params = params.set('sortField', query.sortField);
    if (query.sortOrder) params = params.set('sortOrder', query.sortOrder);

    this.setHeaders();
    return this.http.get(`${environment.apiUrl}/admin/v1/users`, { headers: this.headers, params: params });
  }

  createUser(payload: PayloadUpdateUser) {
    return this.http.post(`${environment.apiUrl}/admin/v1/users`, payload);
  }

  updateUserInfo(userId: string, payload: PayloadUpdateUser) {
    return this.http.put(`${environment.apiUrl}/admin/v1/users/${userId}`, payload);
  }

  deactivateUser(userId: string) {
    return this.http.patch(`${environment.apiUrl}/admin/v1/users/${userId}/status`, { status: UserStatus.inactive });
  }

  removeUser(userId: string) {
    return this.http.delete(`${environment.apiUrl}/admin/v1/users/${userId}`);
  }

  activateUser(userId: string) {
    return this.http.patch(`${environment.apiUrl}/admin/v1/users/${userId}/status`, { status: UserStatus.active });
  }

  uploadUsersFromXSLX(payload: ExcelInfoToUpload) {
    return this.http.post(`${environment.apiUrl}/admin/v1/users/upload`, payload);
  }

  sendDbUserForAdmin() {
    return this.http.get(`${environment.apiUrl}/admin/v1/users/download`, { headers: this.headers });
  }

  //Core Nuevo (Backend-Services)

  getUnitsForSearchByBuildingId(id) {
    return this.http.get(`${environment.apiUrl}/super/v1/units/${id}/all`, { headers: this.headers });
  }

  getUnitsByBuildingId(id, pageP?, rowsPerP?, search?, status?: string) {
    search = search ? `&unit=${search}` : '';
    const page = pageP ? pageP : this.page;
    const rowsPerPage = rowsPerP ? rowsPerP : 10;

    const isStatus = status ? `&status=${status}` : '';

    return this.http.get(`${environment.apiUrl}/super/v1/units/${id}?page=${page}&rowsPerPage=${rowsPerPage}${search}${isStatus}`, { headers: this.headers });
  }

  getInvitationsByBuilding(id, page?, size?, type?, search?, date?, unit?, insideBuild?) {
    page = page ? page : 1;
    size = size ? size : 12;
    type = type ? `&type=${type}` : '';
    search = search ? `&search=${search}` : '';
    unit = unit ? `&unit=${unit}` : '';
    const startDate = date?.startDate ? `&startDate=${date.startDate}` : '';
    const endDate = date?.endDate ? `&endDate=${date.endDate}` : '';
    const insideBuilding = insideBuild ? `&insideBuilding=${insideBuild}` : '';
    let sort = '';
    if (date && date.startDate && date.startDate) {
      sort = '&sortField=date&sortOrder=1';
    }
    this.setHeaders();
    return this.http.get(
      `${environment.apiUrl}/super/v1/invitations/${id}?size=${size}&page=${page}${type}${search}${startDate}${endDate}${unit}${insideBuilding}${sort}`,
      { headers: this.headers },
    );
  }

  getReservationsByBuildingId(id, pageP?, unit?, area?, date?, search?) {
    const page = pageP ? pageP : this.page;
    const rowsPerPage = 6;
    unit = unit ? `&unit=${unit}` : '';
    area = area ? `&area=${area}` : '';
    let startDate = date ? `&startDate=${date.startDate}` : '';
    let endDate = date ? `&endDate=${date.endDate}` : '';
    search = search ? `&search=${search}` : '';
    return this.http.get(
      `${environment.apiUrl}/super/v1/reservations/${id}?page=${page}&rowsPerPage=${rowsPerPage}${unit}${area}${startDate}${endDate}${search}`,
      { headers: this.headers },
    );
  }

  getReservationsByBuildingIdMacro(id, page = 1, query?) {
    let params = new HttpParams();
    params = params.set('page', page);
    return this.http.get(`${environment.apiUrl}/super/v1/reservations/${id}?${query}`, { headers: this.headers, params });
  }

  getTempUsers(buildingId: string, params: HttpParams) {
    return this.http.get(`${environment.apiUrl}/super/v1/tmp-users/${buildingId}`, { headers: this.headers, params });
  }

  fetchUsers(page, limit, search, find?) {
    const params = search ? `&${search}` : '';
    find = find ? `&search=${find}` : '';
    return this.http
      .get(`${environment.apiUrl}/user?page=${page}&rowsPerPage=${limit}${params}${find}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  fetchUsersSub(page, limit, search, find?) {
    const params = search ? `&${search}` : '';
    find = find ? `&search=${find}` : '';
    return this.http.get(`${environment.apiUrl}/user?page=${page}&rowsPerPage=${limit}${params}${find}`);
  }

  storeUser(data, role, status, building?) {
    const buildObject = building ? building : this.getUser().buildings[0];
    const _id = data.user.unit ? (data.user.unit._id ? data.user.unit._id : data.user.unit.id) : null;
    const number = data.user.unit ? data.user.unit.number : null;
    let unit = null;

    if (_id) unit = { _id };
    else unit = { number };

    delete data.user.unit;
    // delete data.user.apartmentId;
    data = {
      ...data,
      building: { _id: buildObject._id },
      roles: [role],
      status: status,
      unit,
    };
    return this.http
      .post(`${environment.apiUrl}/user`, data)
      .toPromise()
      .then((res: any) => {
        return res.object;
      })
      .catch((err) => {
        console.log('errr: ', err);
        return Promise.reject(err);
      });
  }

  getUserProfile() {
    return this.http.get(`${environment.apiUrl}/profile/v1/info`);
  }

  updateAdminEmail(email: string) {
    return this.http.put(`${environment.apiUrl}/profile/v1/email`, { email });
  }

  updateAdminInfo(name: string, phoneDetail: PhoneDetail) {
    return this.http.put(`${environment.apiUrl}/profile/v1/info`, { name, phoneDetail });
  }

  updateUser(data, role, status) {
    delete data.user.apartmentNumber;
    delete data.user.apartmentId;

    data = {
      ...data,
      roles: [role],
      status: status,
    };
    return this.http
      .put(`${environment.apiUrl}/user/${data.user.id}`, data)
      .toPromise()
      .then((res: any) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteUser(id, status) {
    const building = this.getBuilding();
    return this.http
      .put(`${environment.apiUrl}/user/update-status/${id}`, { status, building_id: building._id })
      .toPromise()
      .then((res) => {
        console.log('res update: ', res);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  fetchTmpUser(page, limit, status, search, advanceSearch?) {
    let params = new HttpParams();
    if (page) params = params.set('page', page);
    if (limit) params = params.set('rowsPerPage', limit);
    params = params.set('status', status);
    if (search !== '') params = params.set('search', search);
    advanceSearch = advanceSearch && advanceSearch !== '' ? advanceSearch: '';

    return this.http
      .get(`${environment.apiUrl}/admin/v1/tmp-users?${advanceSearch}`, { headers: this.headers, params: params })
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  updateTmpUser(tmpUserId: string, payload: PayloadUpdateTmpUser) {
    return this.http
      .put(`${environment.apiUrl}/admin/v1/tmp-users/${tmpUserId}`, payload)
      .toPromise()
      .then((res: any) => {
        return res.object;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  deleteTmpUser(data) {
    return this.http
      .delete(`${environment.apiUrl}/admin/v1/tmp-users/${data.user.id}/deleted`, data)
      .toPromise()
      .then((res: any) => {
        return res.object;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  acceptTmpUser(data) {
    const tmpUserId = data.user.id;
    delete data.user;
    return this.http
      .patch(`${environment.apiUrl}/admin/v1/tmp-users/${tmpUserId}/accepted`, data)
      .toPromise()
      .then((res: any) => {
        return res.object;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  uploadPicture(base64: string, format: string) {
    return this.http.put(`${environment.apiUrl}/profile/v1/image`, { base64, format });
  }

  updateFcmToken(id, fcmToken) {
    return this.http
      .put(`${environment.apiUrl}/users/${id}/fcmToken`, { fcmToken })
      .toPromise()
      .then((res: any) => {
        return res.object;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  invitationResponse(data: any, invitation_id: any) {
    return this.http.post(`${environment.apiUrl}/v2/invitation/${invitation_id}/update-status`, data);
  }

  // SERVICIOS PARA LOS SUPER USUARIOS
  getUserById(user_id: string) {
    return this.http.get(`${environment.apiUrl}/super/v1/users/${user_id}`);
  }

  getAllUsersBeingSuper(params: any) {
    let parameters = new HttpParams().set('email', params.email);
    !params.building ? null : (parameters = parameters.set('buildingId', params.building));
    !params.unit ? null : (parameters = parameters.set('unitId', params.unit));
    parameters = parameters.set('page', params.page);
    parameters = parameters.set('rowsPerPage', params.limit);
    return this.http.get(`${environment.apiUrl}/super/v1/users`, { params: parameters });
  }

  getRols() {
    return this.http.get(`${environment.apiUrl}/super/v1/users/user-roles`);
  }

  createUserBeingSuper(user: any) {
    return this.http.post(`${environment.apiUrl}/super/v1/users`, user);
  }

  updateUserBeingSuper(user: any) {
    return this.http.put(`${environment.apiUrl}/super/v1/users/${user.id}`, user);
  }

  updateUserBuilding(user_id: string, building_data: any) {
    return this.http.put(`${environment.apiUrl}/super/v1/users/${user_id}/update-buildings`, building_data);
  }

  updateUserEmail(user_id: string, user_data: any) {
    return this.http.put(`${environment.apiUrl}/super/v1/users/${user_id}/update-email`, { email: user_data.email });
  }

  updateUserNotification(user_id: string, notifications_data: any) {
    return this.http.put(`${environment.apiUrl}/super/v1/users/${user_id}/update-notifications`, notifications_data);
  }

  updateUserPassword(user_id: string, password_data: any) {
    return this.http.put(`${environment.apiUrl}/super/v1/users/${user_id}/update-password`, password_data);
  }

  generateUserPassword(user_id: any) {
    return this.http.put(`${environment.apiUrl}/super/v1/users/${user_id}/generate-password`, {});
  }

  updateAccess(user_id: string, status: any) {
    return this.http.put(`${environment.apiUrl}/super/v1/users/${user_id}/change-user-status`, status);
  }

  createBuildingOfUSer(data: any) {
    return this.http.post(`${environment.apiUrl}/super/v1/users/new-buildings`, data);
  }

  deleteBuildingOfUSer(building_id: string) {
    return this.http.delete(`${environment.apiUrl}/super/v1/users/${building_id}/delete-buildings`);
  }

  deleteUserOfBuilding(userId: string) {
    return this.http.delete(`${environment.apiUrl}/super/v1/users/${userId}`);
  }

  sendUserInformationToBuilding(building_id:string) {
    return this.http.get(`${environment.apiUrl}/super/v1/users/buildings/${building_id}`);
  }

  updateLanguage(language:string){
    return this.http.patch(`${environment.apiUrl}/profile/v1/lang`,{ lang: language });
  }
}
