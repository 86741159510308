export enum ColumnsTableDoors {
  name = 'name',
  type = 'type',
  status = 'status',
  building = 'building',
  door = 'id',
  actions = 'actions'
}

export enum DoorType {
  entrance = 'entrance',
  exit = 'exit',
}

export enum DoorStatus {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export interface CreateDoor {
  name: string;
  type: DoorType;
  buildingsId: string[];
}
