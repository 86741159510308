<section class="container__filters-common">
  <section class="common__filters">
    <form class="container__filters-common-inputs" [formGroup]="formFilters">
      <ng-container *ngFor="let item of showfilters">
        <mat-form-field *ngIf="item === IShowFilters.search" class="custom__class-input-filters" appearance="outline"
          [floatLabel]="floatLabel">
          <input matInput [placeholder]="'modal.search' | translate" formControlName="search" />
          <mat-icon matSuffix *ngIf="!formFilters.get('search')?.value">search</mat-icon>
          <mat-icon matSuffix style="cursor: pointer;" *ngIf="formFilters.get('search')?.value"
            (click)="btnClearControl('search', 'search')">cancel</mat-icon>
        </mat-form-field>

        <mat-form-field *ngIf="item === IShowFilters.company && !isMacro" class="custom__class-input-filters"
          appearance="outline" floatLabel="never">
          <input type="text" [placeholder]="'modal.company' | translate" matInput [matAutocomplete]="autoCompany"
            formControlName="company" #triggerC="matAutocompleteTrigger">
          <mat-icon matSuffix *ngIf="!isOpenPanelCompany && !formFilters.get('company')?.value"
            (click)="openOrClosePanel($event, triggerC)" style="cursor: pointer;">keyboard_arrow_up</mat-icon>
          <mat-icon matSuffix *ngIf="isOpenPanelCompany && !formFilters.get('company')?.value"
            (click)="openOrClosePanel($event, triggerC)" style="cursor: pointer;">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix style="cursor: pointer;" *ngIf="formFilters.get('company')?.value"
            (click)="btnClearControl('company', 'buildingId')">cancel</mat-icon>

          <mat-autocomplete autoActiveFirstOption #autoCompany="matAutocomplete" (closed)="isOpenPanelCompany = true"
            (opened)="isOpenPanelCompany = false" (optionSelected)="selectCompany($event.option.value)">
            <mat-option *ngFor="let option of communities" [value]="option">{{option.buildingName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>


        <mat-form-field *ngIf="item === IShowFilters.locals" class="custom__class-input-filters" appearance="outline"
          [floatLabel]="floatLabel">
          <input type="text" [placeholder]="'superadministration.table.local' | translate" matInput
            #triggerl="matAutocompleteTrigger" [matAutocomplete]="autoLocal" formControlName="unit">
          <mat-icon matSuffix *ngIf="!isOpenPanelUnit && !formFilters.get('unit')?.value"
            (click)="openOrClosePanel($event, triggerl)" style="cursor: pointer;">keyboard_arrow_up</mat-icon>
          <mat-icon matSuffix *ngIf="isOpenPanelUnit && !formFilters.get('unit')?.value"
            (click)="openOrClosePanel($event, triggerl)" style="cursor: pointer;">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix style="cursor: pointer;" *ngIf="formFilters.get('unit')?.value"
            (click)="btnClearControl('unit', 'unit')" style="cursor: pointer;">cancel</mat-icon>

          <mat-autocomplete autoActiveFirstOption #autoLocal="matAutocomplete" (closed)="isOpenPanelUnit = true"
            (opened)="isOpenPanelUnit = false" (optionSelected)="selectUnit($event.option.value)">
            <mat-option *ngFor="let option of units" [value]="option">
              {{option.number}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="item === IShowFilters.type" class="custom__class-input-filters" appearance="outline"
          [floatLabel]="floatLabel">
          <input [placeholder]="'superadministration.table.typeVisit' | translate" matInput
            [matAutocomplete]="autoTypeVisit" formControlName="type" #triggerp="matAutocompleteTrigger">
          <mat-icon matSuffix *ngIf="!isOpenPanelTypeVisit && !formFilters.get('type')?.value"
            (click)="openOrClosePanel($event, triggerp)" style="cursor: pointer;">keyboard_arrow_up</mat-icon>
          <mat-icon matSuffix *ngIf="isOpenPanelTypeVisit && !formFilters.get('type')?.value"
            (click)="openOrClosePanel($event, triggerp)" style="cursor: pointer;">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix style="cursor: pointer;" *ngIf="formFilters.get('type')?.value"
            (click)="btnClearControl('type', 'type')" style="cursor: pointer;">cancel</mat-icon>

          <mat-autocomplete autoActiveFirstOption #autoTypeVisit="matAutocomplete"
            (closed)="isOpenPanelTypeVisit = true" (opened)="isOpenPanelTypeVisit = false"
            (optionSelected)="selectTypeVisit($event.option.value)">
            <mat-option *ngFor="let option of accesTypeList;" [value]="option">
              {{ option.display | translate }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="item === IShowFilters.state" class="custom__class-input-filters" appearance="outline"
          [floatLabel]="floatLabel">
          <mat-icon matSuffix
            *ngIf="isOpenPanelState && !formFilters.get('state')?.value">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix style="cursor: pointer;" *ngIf="formFilters.get('state')?.value"
            (click)="btnClearControl('state', 'status')" style="cursor: pointer;">cancel</mat-icon>

          <mat-select [formControl]="formFilters.controls['state']" multiple
            [placeholder]="'servicessuper.services.servicefilterstatus' | translate"
            (selectionChange)="selectState($event.value)" [panelClass]="'custom-checkbox'">
            <mat-select-trigger>
              <span *ngIf="formFilters.controls['state']?.value">
                {{ formFilters.controls['state'].value?.length >= 2 ? ('modal.multipleStatesSelected' | translate) :
                formFilters.controls['state'].value[0].display | translate }}
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let option of accesStateList" [value]="option">{{ option.display | translate
              }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="item === IShowFilters.door" class="custom__class-input-filters" appearance="outline"
          [floatLabel]="floatLabel">
          <input [placeholder]="'menu.door' | translate" matInput [matAutocomplete]="autoDoor" formControlName="door"
            #triggerd="matAutocompleteTrigger">
          <mat-icon matSuffix *ngIf="!isOpenPanelDoor && !formFilters.get('door')?.value"
            (click)="openOrClosePanel($event, triggerd)" style="cursor: pointer;">keyboard_arrow_up</mat-icon>
          <mat-icon matSuffix *ngIf="isOpenPanelDoor && !formFilters.get('door')?.value"
            (click)="openOrClosePanel($event, triggerd)" style="cursor: pointer;">keyboard_arrow_down</mat-icon>
          <mat-icon matSuffix style="cursor: pointer;" *ngIf="formFilters.get('door')?.value"
            (click)="btnClearControl('door', 'doorId')" style="cursor: pointer;">cancel</mat-icon>

          <mat-autocomplete autoActiveFirstOption #autoDoor="matAutocomplete" (closed)="isOpenPanelDoor = true"
            (opened)="isOpenPanelDoor = false" (optionSelected)="selectDoor($event.option.value)">
            <mat-option *ngFor="let option of doorList" [value]="option">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="item === IShowFilters.dateRange" class="custom__class-input-filters"
          appearance="outline">
          <mat-label *ngIf="!isPickerOpen">{{ 'modal.placeholder.lastDial' | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
            <input matStartDate placeholder="start" formControlName="startDate" />
            <input matEndDate placeholder="end" formControlName="endDate" (dateChange)="selectDateRange()" />
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon *ngIf="isPickerOpen">
              keyboard_arrow_up
            </mat-icon>
            <mat-icon matDatepickerToggleIcon *ngIf="!isPickerOpen">
              keyboard_arrow_down
            </mat-icon>
          </mat-datepicker-toggle>

          <mat-date-range-picker #picker panelClass="custom__class-datepicker" (opened)="isPickerOpen = false"
            (closed)="isPickerOpen = false"></mat-date-range-picker>
        </mat-form-field>
      </ng-container>
    </form>
  </section>
  <section class="common__buttons">
    <button *ngIf="isClearFilters" (click)="resetFilters()" class="btn-secondary-outlined" type="button">
      {{'modal.button.cleanFilter' | translate}}</button>
  </section>
</section>