import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import moment from 'moment';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { BuildingMacroCommunityService } from 'src/app/macro-community/services/building.service';
import { BuildingQueryParams } from 'src/app/macro-community/interfaces/building.interface';
import { ApartmentService } from 'src/app/services/apartment.service';
import { AreaService } from 'src/app/services/area.service';
import { BuildingService } from 'src/app/services/building.service';
import { UserAdministrationService } from 'src/app/macro-community/services/administration/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ACTION_LOADING } from 'src/app/component/store/actions/actions';
import { unitsResponse } from 'src/app/interface/units.interface';
import { BuildingAdminMacro } from 'src/app/macro-community/interfaces/building.interface';
import { AccessCardStatus, AccessEntryStatus, AccessTypeMacro } from 'src/app/interface/frequentAccess.inteface';
import { AccessCardType } from '../../interface/frequentAccess.inteface';
import { CallType } from 'src/app/interface/calls.interface';
import { CommonFilters } from 'src/app/interface/data.interface';

@Component({
  selector: 'app-common-filters',
  templateUrl: './common-filters.component.html',
  styleUrls: ['./common-filters.component.scss'],
})
export class CommonFiltersComponent implements OnInit {
  @Input() showFilters: string[] = [];
  @Input() buildingId: string;
  @Input() isMacro: boolean = false;
  @Input() users: any[] = [];
  @Input() defaultValues: Partial<CommonFilters>;
  @Output() sendWaitedSearch: EventEmitter<string> = new EventEmitter<string>(); //It is used in cases where it is required to perform a filter with the text written outside the component, since it is necessary to consult the service again with the new parameter.
  @Output() queryOutput: EventEmitter<string> = new EventEmitter<string>();
  @Output() haveAction?= new EventEmitter<boolean>();
  @Output() selectedOptions?= new EventEmitter<any>();

  @ViewChild('buildingCommunity', { static: false }) buildingCommunity: NgSelectComponent;

  form: FormGroup;
  watchUnit = true;
  searching = null;
  units: { id: string; number: string }[] = [];
  areas: { id: string; name: string }[];
  groupUsers: any[];
  // FILTER BY ROLES
  roles = [
    { value: 'tenant', display: 'rols.tenant' },
    { value: 'committee', display: 'rols.committee' },
    { value: 'proprietor', display: 'rols.proprietor' },
    { value: 'vigilant', display: 'rols.vigilant' },
    { value: 'admin', display: 'rols.admin' },
    { value: 'customized', display: 'rols.customized' },
  ];
  macroRoles = [
    { value: 'collaborator', display: 'rols.collaborator' },
    { value: 'external', display: 'rols.external' }
  ];
  // FILTER BY ACTIVEUSER
  activeUsersOptions = [
    { value: 'true', display: 'adverb.yes' },
    { value: 'false', display: 'adverb.no' }
  ];
  // FILTER BY CATEGORY PQRS
  pqrsCategories = [
    { value: 'request', display: 'pqrs.categories.request' },
    { value: 'claim', display: 'pqrs.categories.claim' },
    { value: 'complain', display: 'pqrs.categories.complain' },
    { value: 'suggestion', display: 'pqrs.categories.suggestion' }
  ];
  // FILTER BY STATUS PQRS
  pqrsStatus = [
    { value: 'expired', display: 'status2.expired' },
    { value: 'pending', display: 'status.pending' },
    { value: 'inProgress', display: 'status.inProgress' },
    { value: 'solved', display: 'status.solved' }
  ];
  // FILTER BY STATUS PQRS
  reservationStatus = [
    { value: 'approved', display: 'status.approved' },
    { value: 'canceled', display: 'status.canceled' },
    { value: 'rejected', display: 'status.rejected' },
    { value: 'waiting_payment', display: 'status.waiting_payment' },
    { value: 'waiting_approve', display: 'status.waiting_approve' }
  ];
  // FILTER BY ACCESS STATUS
  accessStatus = [
    { value: AccessCardStatus.ACTIVE, display: 'status.active', icon:'../../../assets/images/icons/icon-activo.svg' },
    { value: AccessCardStatus.EXPIRED, display: 'status.expired', icon:'../../../assets/images/icons/icon-vencido.svg' },
    { value: AccessCardStatus.DELETED, display: 'status.deleted', icon:'../../../assets/images/icons/icon-eliminado.svg' }
  ];
  frequentAccessActiveStatus = [
    { value: AccessEntryStatus.active, display: 'frequentAccess.securityCenter.statusEntry.active', icon:'../../../assets/images/icons/icon-activo.svg' },
    { value: AccessEntryStatus.displacement, display: 'frequentAccess.securityCenter.statusEntry.displacement', icon:'../../../assets/images/icons/icon-entry.svg' },
    { value: AccessEntryStatus.timeExceeded, display: 'frequentAccess.securityCenter.statusEntry.timeExceeded', icon:'../../../assets/images/icons/icon-vencido.svg' }
  ];
  // FILTER BY ACCESS TYPE
  accessType = [
    { value: AccessCardType.COLLABORATOR, display: 'frequentAccess.accessTypes.collaborator' },
    { value: AccessCardType.PATIENT, display: 'frequentAccess.accessTypes.patient' },
    { value: AccessCardType.VISIT, display: 'frequentAccess.accessTypes.visit' }
  ];

  // FILTER BY ACCESS TYPE MACRO
  accessTypeMacro = [
    { value: AccessTypeMacro.COLLABORATOR, display: 'frequentAccess.accessTypes.collaborator' },
    { value: AccessTypeMacro.VISIT, display: 'frequentAccess.accessTypes.visit' },
    { value: AccessTypeMacro.PROVIDER, display: 'frequentAccess.accessTypes.provider' }
  ];

  // FILTER BY TYPE USER ACCESS
  typesFrequentAccess = [
    { value: 'family', display: 'status.family'},
    { value: 'trainer', display: 'status.trainer'},
    { value: 'employee', display: 'status.employee'},
    { value: 'provider', display: 'status.provider'},
    { value: 'guest', display: 'status.guest'},
  ]
  // FILTER BY DAYS
  daysFrequentAccess = [
    { value:"0", display:"days.sunday" },
    { value:"1", display:"days.monday" },
    { value:"2", display:"days.tuesday" },
    { value:"3", display:"days.wednesday" },
    { value:"4", display:"days.thursday" },
    { value:"5", display:"days.friday" },
    { value:"6", display:"days.saturday" },
  ]
  // FILTER BY STATUS FREQUENT ACCESS
  statusFrequentAccess = [
    { value:"active", icon:"https://munily-public-cdn.s3.amazonaws.com/general/frequentAccessActiveStatus.png", display:"status.active" },
    { value:"deleted", icon:"https://munily-public-cdn.s3.amazonaws.com/general/frequentAccessTimeStatus.png", display:"status.deleted" },
    { value:"expired", icon:"https://munily-public-cdn.s3.amazonaws.com/general/frequentAccessWarningStatus.png", display:"status.expired" },
    { value:"completed", icon:"https://munily-public-cdn.s3.amazonaws.com/general/frequentAccessActiveStatus.png", display:"status.completed" },
  ]

  //FILTER BY TYPE VISIT
  VisitType = {
    visit: 'visit',
    delivery: 'delivery',
    'visit-convenyance': 'green',
    parcel: 'parcel',
    providers: 'providers',
    selfRegister: 'selfRegistrationVisit',
  };
  typeVisits = [
    { value:"providers", display:"invitations.types.providers" },
    { value:"delivery", display:"invitations.types.delivery" },
    { value:"visit", display:"invitations.types.visit" },
    { value:"frequentAccess", display:"invitations.types.frequentAccess" },
    { value:"priority", display:"invitations.types.priority" },
  ]

  //FILTER BY CALL TYPE
  typeCalls = [
    { value: CallType.vigilantCallInvitation, display:"vigilantCalls.types.invitation"},
    { value: CallType.vigilantCallDirectory, display:"vigilantCalls.types.directory"}
  ];

  communities: BuildingAdminMacro[];
  constructor(
    private formBuilder: FormBuilder,
    private unitsService: ApartmentService,
    private buildingService: BuildingService,
    private userAdministrationService: UserAdministrationService,
    private buildingMacroCommunityService: BuildingMacroCommunityService,
    private translate: TranslateService,
    private areaService: AreaService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    if(this.buildingId){
      this.buildingService.getOneBuildingById(this.buildingId).subscribe((data:any)=>{
        if(data.data.config.macrocommunity && data.data.config.macrocommunity.enable){
          if(data.data.config.macrocommunity.communities[0].type === 'macrocommunity') this.isMacro = true;
        }
        if (this.isMacro){
          this.getReadyMacroFilters()
          this.roles.push(...this.macroRoles);
        };
      });
    }
    this.getReadyForm();
    this.getReadyInputs();
    if (this.showFilter('groupId')) this.getAllUserGroup();
    if (this.showFilter('community')) this.getReadyCommunityInput();
    if (this.showFilter('company')) this.getReadyCommunityInput();
    // this.clear(true);
  }

  getReadyMacroFilters() {
    const commiteIndex = this.roles.findIndex((e) => e.value == 'committee');
    this.roles.splice(commiteIndex, 1);
    const vigilantIndex = this.roles.findIndex((e) => e.value == 'vigilant');
    this.roles.splice(vigilantIndex, 1);
    const groupIndex = this.roles.findIndex((e) => e.value == 'usergroup');
    this.roles.splice(groupIndex, 1);
  }

  getReadyInputs() {
    if (this.showFilters.includes('area')) this.getReadyAreaInput();
    if (this.buildingId && this.showFilters.includes('unit')) this.getReadyUnitInput();
    if (this.buildingId && this.showFilters.includes('locals')) this.getReadyUnitInput();
    if (this.buildingId && this.showFilters.includes('community')) this.getReadyCommunityInput();
  }

  getReadyUnitInput() {
    this.buildingService.getUnitsByBuilding(this.buildingId).subscribe(
      (res: any) => (this.units = res.data.map((e) => ({ id: e.unitId, number: e.unitNumber }))),
      (err) => console.error(err),
    );
  }

  getReadyAreaInput() {
    this.areaService.getAreas(this.buildingId, true, false, 1, 'active').subscribe(
      (res: any) => (this.areas = res.data.docs.map((e) => ({ id: e.id, name: e.name }))),
      (err) => console.error(err),
    );
  }

  getReadyForm() {
    this.form = this.formBuilder.group({
      user: [ this.defaultValues?.user ?? null],
      name: [ this.defaultValues?.name ?? null],
      building: [ this.defaultValues?.building ?? null],
      dni: [ this.defaultValues?.dni ?? null],
      docId:[ this.defaultValues?.docId?? null],
      roles: [ this.defaultValues?.roles ?? null],
      unitId: [ this.defaultValues?.unitId ?? null],
      unitNumber:[ this.defaultValues?.unitNumber ?? null],
      email: [ this.defaultValues?.email ?? null],
      search: [ this.defaultValues?.search ?? null],
      activeUsers: [ this.defaultValues?.activeUsers ?? null],
      pqrsCategory: [ this.defaultValues?.pqrsCategory ?? null],
      pqrsStatus: [ this.defaultValues?.pqrsStatus ?? null],
      reservationStatus: [ this.defaultValues?.reservationStatus ?? null],
      accessStatus:[ this.defaultValues?.accessStatus ?? null],
      accessEntryStatus:[ this.defaultValues?.accessEntryStatus ?? null],
      accessType:[ this.defaultValues?.accessType ?? null],
      area: [ this.defaultValues?.area ?? null],
      startDate: [ this.defaultValues?.startDate ?? null],
      endDate: [ this.defaultValues?.endDate ?? null],
      groupId: [ this.defaultValues?.groupId ?? null],
      statusAccess: [ this.defaultValues?.statusAccess ?? null],
      typeAccess: [ this.defaultValues?.typeAccess ?? null],
      daysAccess: [ this.defaultValues?.daysAccess ?? null],
      typeVisit: [ this.defaultValues?.typeAccess ?? null],
      callType:[ this.defaultValues?.callType ?? null],
      company:[ this.defaultValues?.company ?? null],
      plate:[ this.defaultValues?.plate ?? null]
    });
  }

  clear(notFilter?: boolean) {
    this.form.reset();
    if (!notFilter) this.filter();
  }

  unitFilter(unitFilter: NgSelectComponent) {
    if (this.buildingId) return;
    this.unitSearchDebounce(unitFilter);
    if (this.units.length === 0) this.unitSearchDebounce(unitFilter);
  }

  unitSearchDebounce(unitFilter: NgSelectComponent) {
    this.units = [];
    if (unitFilter && this.watchUnit) {
      this.watchUnit = false;
      fromEvent(unitFilter.element, 'keyup')
        .pipe(
          map((event: any) => {
            return event.target.value;
          }),
          filter((res) => res.length > 0),
          debounceTime(500),
          distinctUntilChanged(),
        )
        .subscribe((text: string) => {
          this.getUnits(text);
        });
    }
  }

  async getUnits(search: string) {
    const unitsSimplified = await this.unitsService.fetchApartmentsSimplify(search);
    this.units = await this.unitsService.onlyNameAndUnit(unitsSimplified);
  }

  getAllUserGroup() {
    this.userAdministrationService.getAllUserGroups().subscribe((resp: any) => {
      this.groupUsers = resp.data.docs;
    });
  }

  showFilter(filter: string) {
    if (this.showFilters.includes(filter)) {
      return true;
    }
    return false;
  }
  getReadyCommunityInput() {
    this.buildingMacroCommunityService
      .getCommunitiesBuilding({ all: 'true', buildingId: this.buildingId })
      .subscribe((res) => (this.communities = res.docs.filter(building => building)));
  }

  formatDate(key: string, display: string) {
    if (key == 'startDate') return moment(display).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    if (key == 'endDate') return moment(display).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    return display;
  }

  filter() {
    const keys = Object.keys(this.form.value);
    let output = '';

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let formItem = this.form.value[key];
      if (formItem) {
        if (key.includes('startDate') || key.includes('endDate')) formItem = this.formatDate(key, formItem);
        const param = `&${key}=${formItem}`;
        output = output + param;
      }
    }
    this.returnQueryOutput(output);
  }

  returnQueryOutput(output: string) {
    this.queryOutput.emit(output);
  }

  waitingStopTyping(search: string, filter: string) {
    clearInterval(this.searching);
    if (search === null || search === '') return;
    this.searching = setTimeout(async () => {
      this.sendWaitedSearch.emit(search);
    }, 1500);
  }

  clearSearch() {
    this.sendWaitedSearch.emit(null);
  }

  onOpenModal(openModal: boolean) {
    this.haveAction.emit(openModal);
  }

  selectedOption(event: string) {
    const group = this.groupUsers.find((group) => group.id === event);
    this.selectedOptions.emit(group);
  }

  changeCommunityEvent(buildingId) {
    if (!buildingId) {
      if (this.units.length > 0) this.form.get('unitId').reset();
      this.units.length = 0;
      return;
    }
    this.units.length = 0;
    this.buildingService.getUnitsByBuilding(buildingId).subscribe(
      (units: { data: unitsResponse[] }) => {
        this.units = this.buildingService.unitsMapper(units.data);
      },
      (error) => {
        this.toastr.error(this.translate.instant('macroCommunity.error.loadCommunitiesUser'));
      },
    );
  }

  setVisibleLoading(isVisible: boolean) {
    this.buildingService.isLoading({ action: ACTION_LOADING, payload: isVisible });
  }
}
