import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericModal } from 'src/app/interface/data.interface';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  title: string = 'modal.changesSaved'

  constructor(@Inject(MAT_DIALOG_DATA) private payload?: GenericModal) {
    if (this.payload && this.payload.title) {
      this.title = payload.title;
    }
  }

  ngOnInit(): void {
  }

}
