import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DoorType } from 'src/app/interface/door.interface';
import { AccessEntryStatus } from 'src/app/interface/frequentAccess.inteface';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'access-timeline',
  templateUrl: './access-timeline.component.html',
  styleUrls: ['./access-timeline.component.scss']
})
export class AccessTimelineComponent implements OnInit {
  @Input() accessEntries: any[] = [];

  doorIcon = [
    {
      type: DoorType.entrance,
      icon: "entry"
    },
    {
      type: DoorType.exit,
      icon: "exit"
    }
  ];

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.addSvgIcon('entry', 'assets/images/icons/icon-entry.svg');
    this.addSvgIcon('exit', 'assets/images/icons/icon-exit.svg');
  }

  ngOnInit(): void {
    this.changeStatusLabel();
  }

  getIconDooType(type: DoorType) {
    const icon = this.doorIcon.find(door => door.type === type);
    return icon.icon
  }

  addSvgIcon(name: string, path: string) {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }

  changeStatusLabel() {
    for (let i = 0; i < this.accessEntries.length; i++) {
      const entry = this.accessEntries[i];
      const nextEntry = this.accessEntries[i+1] ?? null;

      switch (entry.status) {
        case AccessEntryStatus.completed:
          entry.status = AccessEntryStatus.left;
          break;
        case AccessEntryStatus.active:
          entry.status = AccessEntryStatus.entered;
          break;
        case AccessEntryStatus.timeExceeded:
          if(nextEntry && nextEntry.date){
            const nextMark = new Date(nextEntry.date);
            entry.timeEx = this.calculateExceededTime(entry.date, nextMark);
          }else{
            const currentTime = new Date();
            entry.timeEx = this.calculateExceededTime(entry.date, currentTime);
          }
          break;
      }
    }
  }
  

  calculateExceededTime(expirationDate: Date, until: Date): { hours: string, minutes: string } {
    const expirationTime = new Date(expirationDate);
    if (!expirationDate || (until <= expirationTime)) {
      return { hours: '00', minutes: '00' }; // Aún no está vencido
    }

    const timeDifference = until.getTime() - expirationTime.getTime();
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    return { hours: (hours < 10) ? '0' + String(hours) : String(hours), minutes: (minutes < 10) ? '0' + String(minutes) : String(minutes)};
  }
}
